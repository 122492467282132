// auth
export const AUTH_SET_USER = '@webapp-mkt/auth/AUTH_SET_USER';
export const AUTH_ERROR = '@webapp-mkt/auth/AUTH_ERROR';
export const AUTH_SUCCESS = '@webapp-mkt/auth/AUTH_SUCCESS';
export const AUTH_LOGIN = '@webapp-mkt/auth/AUTH_LOGIN';
export const AUTH_LOGOUT = '@webapp-mkt/auth/AUTH_LOGOUT';

// password
export const PASSWORD_RESET = '@webapp-mkt/auth/PASSWORD_RESET';
export const PASSWORD_RESET_CONFIRM = '@webapp-mkt/auth/PASSWORD_RESET_CONFIRM';
export const PASSWORD_CHANGE = '@webapp-mkt/auth/PASSWORD_CHANGE';

// submit
export const SUBMIT_LOGIN = '@webapp-mkt/auth/SUBMIT_LOGIN';
export const SUBMIT_LOGOUT = '@webapp-mkt/auth/SUBMIT_LOGOUT';
export const SUBMIT_PASSWORD_CHANGE = '@webapp-mkt/auth/SUBMIT_PASSWORD_CHANGE';
export const SUBMIT_PASSWORD_RESET = '@webapp-mkt/auth/SUBMIT_PASSWORD_RESET';
export const SUBMIT_ERROR = '@webapp-mkt/auth/SUBMIT_ERROR';
export const SUBMIT_SUCCESS = '@webapp-mkt/auth/SUBMIT_SUCCESS';

// other
export const SET_PENDING_CONFIRMATION = '@webapp-mkt/auth/SET_PENDING_CONFIRMATION';
export const RESEND_VERIFICATION = '@webapp-mkt/auth/RESEND_VERIFICATION';
