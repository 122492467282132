// eslint-disable-next-line import/no-cycle
import api, { publicApi } from './api';

export const signIn = (email: any, password: any) =>
  publicApi.post('/auth/login/', {
    email,
    password,
  });

export const signOut = () => api.post('/auth/logout/');
