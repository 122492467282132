import * as types from './constants';

// SUBMIT ACTIONS
export type LoginPayload = {
  email: string;
  password: string;
};
export const handleSubmitLogin = (payload: LoginPayload) =>
  ({
    type: types.SUBMIT_LOGIN,
    payload,
  } as const);

export const handleSubmitLogout = () =>
  ({
    type: types.SUBMIT_LOGOUT,
  } as const);

export const handleSubmitPasswordChange = (email: string) =>
  ({
    type: types.SUBMIT_PASSWORD_CHANGE,
    payload: { email },
  } as const);

type HandleSubmitPasswordReset = {
  password: string;
  uid: string;
  token: string;
  username: string;
};

export const handleSubmitPasswordReset = ({
  password,
  uid,
  token,
  username,
}: HandleSubmitPasswordReset) =>
  ({
    type: types.SUBMIT_PASSWORD_RESET,
    payload: {
      password,
      uid,
      token,
      username,
    },
  } as const);

export const resendVerification = (username: string) =>
  ({
    type: types.RESEND_VERIFICATION,
    payload: {
      username,
    },
  } as const);
