import Storage from 'app/shared/utils/Storage';
import Auth from '@aws-amplify/auth';
import Router from 'next/router';
// eslint-disable-next-line import/no-cycle
import { signOut } from 'app/services/api/auth_legacy';

const logout = () => {
  try {
    // cognito
    return Auth.signOut();
  } catch (error) {
    // legacy
    return signOut();
  } finally {
    Storage.remove('authorization');
    Storage.remove('profile_id');
    Router.push('/');
  }
};

export default logout;
