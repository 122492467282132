// External
import Router from 'next/router';
import { AxiosError, AxiosRequestConfig } from 'axios';

// Internal
import Storage from 'app/shared/utils/Storage';
// eslint-disable-next-line import/no-cycle
import logout from 'app/state/auth/utils';

export function interceptorRequestAuthenticationFulfilled(
  request: AxiosRequestConfig,
) {
  const Authorization = Storage.get('authorization');
  if (Authorization) {
    request.headers.common = { Authorization, ...request.headers.common };
  }
  return request;
}

export function interceptorResponseRejected(responseError: AxiosError) {
  const { response } = responseError;
  if (response) {
    if (response.status === 401) {
      logout();
    }
    if (response.status === 404) {
      if (!process.browser) {
        Router.push('/404');
      }
      throw new Error('Not found');
    }
    return Promise.reject(response);
  }
  // unhandled reponse
  return Promise.reject(responseError);
}
