import DateFormat from '../utils/dateFormat';

export const AUTOCOMPLETE = 'autocomplete';
export const INSURANCE = 'insurance';
export const CITY = 'city';
export const ACCEPTS_IN_PERSON = 'accepts_in_person';
export const ACCEPTS_TELEMEDICINE = 'accepts_telemedicine';

export const SCHEDULE_DEFAULT_DATE = {
  start_date: new DateFormat(new Date()).addDays(1).toString(),
  end_date: new DateFormat(new Date()).addDays(4).toString(),
};

export const ONLINE_PAYMENT_BETA_USERS = [1166];
