const get = (key) => {
  if (process.browser) {
    return JSON.parse(window.localStorage.getItem(key));
  }
  return {};
};

const set = (key: string, value: string) => window.localStorage.setItem(key, JSON.stringify(value));

const remove = (key: string) => window.localStorage.removeItem(key);

export default {
  get,
  set,
  remove,
};
