// External
import axios from 'axios';

// Internal
import {
  interceptorRequestAuthenticationFulfilled,
  interceptorResponseRejected,
} from './interceptors';

const baseURL = process.browser
  ? process.env.ICLINIC_MKT_API_URL
  : process.env.ICLINIC_MKT_API_SERVER_URL;

const api = axios.create({ baseURL });
export const publicApi = axios.create({ baseURL });

api.interceptors.request.use(
  interceptorRequestAuthenticationFulfilled,
);

api.interceptors.response.use(
  (response) => response,
  interceptorResponseRejected,
);

export default api;
